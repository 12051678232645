import PurchaseOrderSidebar from '@/components/PurchaseOrderSidebar.vue'
import { AmenityType, ApiResult, AwardedProvider, DriverAssignment, HotelInformation, ReferredProvider, Stop, Trip, Vehicle, VehicleAssignment } from '.'
import { TableViewResult } from '../TableView'
import { Tier } from '../Tier'

export interface Reservation {
  active: boolean
  ada: boolean
  amount: number
  clientTotalAdjusted?: number
  originalAmount: number
  takeRate: number
  allocatedToPreBookingId: number | null
  allocatedToManagedId: string | null
  allocatedFromReservationId: number | null
  assignedDriverPercentage: number
  assignedVehiclePercentage: number
  balance: number
  bookedByName: string
  cancelledOn: string | null
  cancellationStatusKey: string
  ccVerified: boolean
  charterUpQuote: boolean
  checkoutPageId: null
  childId: string | null
  confirmed: boolean
  contractId: number | null
  createdOn: string
  customerAccountName: string | null
  customerId: number
  customerEmail: string
  customerName: string
  customerTotal: number
  tripContactEmail: string
  distance: number
  driverInfoEmailSent: boolean
  drivingTime: number
  dropoffDate: string
  dueDate: string | null
  enterpriseTrackingOnly: null
  firstDropoffAddressName: string
  firstPickupAddressName: string
  firstPickupTimeZone: string
  firstSentDate: string | null
  grossProfit: number
  hash: string | null
  isEnterprise: boolean
  tier: Tier
  isPreBooking: boolean
  isCharterUP: boolean
  managedId: string
  marketplaceClientTotal: number | null
  mountain: boolean
  openForBid: boolean
  parentReservationId: number | null
  parentReservationStatus: string | null
  paymentStatus: string
  pickupDate: string
  pickupLocation: string
  quoteId: number
  quotedOn: null
  reinstateStatusKey: string
  referredTo: ReferredProvider[]
  awardedTo: AwardedProvider
  referralId: null
  referralPassengerCount: number | null
  referralPaymentStatus: string
  referralStatus: string
  referralStatusKey: string | null
  referralAcceptanceExpiration: string
  referralAmount: number
  referredAmount: number
  referringCompany: string
  requiredDrivers: number
  reservationId: number
  reservationStatus: string
  reservationStatusKey: string | null
  reservationType: number
  routeName: string
  sentDate: string
  spab: boolean
  startDate: string
  endDate?: string
  tripId: number
  tripTypeLabel: string
  tripType: string
  trip?: Trip
  stops?: Stop[]
  vehicleAssignments: VehicleAssignment[]
  driverAssignments?: DriverAssignment[]
  requiredVehiclesCount?: number | null
  opportunityStatusTypeId: number | null
  interestedTimestamp: number | null
  journeys?: any[]
  companyId: number
  customerCancellationEmailSent: boolean
  cancellationClassificationLabel: string
  supportTeamClassificationId: number
  productClassificationId: number
  sourcingTeamClassificationId: number
  hotelInformation: HotelInformation
  tripAmenities: AmenityType[]
  purchaseOrder?: PurchaseOrder
  purchaseOrderStatuses: string[]

  requiredVehicles: Vehicle[]
  passengerCount: number
  driverCount: number
  maxReferralAmount: number
}

export interface PurchaseOrder {
  purchaseOrderNumber: string
  purchaseOrderStatus: string
}

export interface Cancellation {
  managedId: string
  receivedOn: string
  pickupDate: string
  firstPickupTimezone: string
  cancelledOn: string
  cancellationStatus: string
  fee: number
  reservationId: number
  referralAmount: number
}

export type ReservationTableViewResult = TableViewResult<Reservation>

export interface MultiTripReferralResult extends ApiResult {
  referrals?: Reservation[]
}

export interface InterestIndicatedResult extends ApiResult {
  interestedTimestamp: number | null
}

export type CancellationTableViewResult = TableViewResult<Cancellation>

export interface TableViewReservation {
  balance: number
  childId: number | null
  createdOn: string
  customerEmail: string
  customerFirstName: string
  customerLastName: string
  customerPhone: string
  customerTotal: number
  firstStopAddress: string
  firstStopAddressTimeZone: string
  hash: string
  lastStopAddress: string
  managedId: string
  marketplaceClientTotal: number
  parentReservationId: number | null
  passengerCount: number
  paymentStatus: string
  quotedOn: string
  reservationId: number
  reservationStatus: string
  reservationStatusKey: string
  reservationStatusLabel: string
  startDate: string
  referredTo: string
  referredBy: string
  tripRouteName: string | null
  tripTypeLabel: string
  vehicles: {
    quantity: number
    vehicleType: { id: number; key: string; label: string }
  }[]
}

export interface OverridePaymentTermsPayload {
  referralId: number
  overrideReasonType: PaymentTermsOverrideReasons
  newPaymentTerms: PaymentTerms
  overrideNotes: string
}

export interface UpdatePurchaseOrderRequestDTO {
  purchaseOrderNumber: string,
  purchaseOrderStatus: string,
}

export enum PaymentTermsOverrideReasons {
  INCORRECT_TRACKED_STATUS = "incorrect_tracked_status",
  COMPLAINT_RESOLVED = "complaint_resolved",
  OTHER = "other",
}

export enum PaymentTerms {
  net_3 = "Net 3",
  net_14 = "Net 14",
  pending = "Pending"
}
